/* ALL */
.header {
  background-color: #1C104E;
  width: 100%; 
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}


/* MACHINE */
.left-header, .right-header {
  display: flex;
  flex-direction: row;
  margin: .5em;
}

.header-logo {
  width: 4em;
  height: 4em;
  cursor: pointer;
}

.header-name {
  height: 4em;
  cursor: pointer;
}

.header-login {
  color: #1affa7;
  font-size: 1.25em;
  font-weight: bold;
  margin: 0.5em;
  padding-top: 0.45em;
  cursor: pointer;
}


/* MOBILE */
.left-header-mobile, .right-header-mobile {
  display: flex;
  flex-direction: row;
  margin: .3em;
  cursor: pointer;
}

.header-logo-mobile {
  width: 2em;
  height: 2em;
  cursor: pointer;
  margin-top: .5em;
  margin-left: .5em;
}

.header-name-mobile {
  height: 2em;
  cursor: pointer;
  margin-top: .5em;
}

.header-login-mobile {
  color: #1affa7;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  margin-right: .5em;
}