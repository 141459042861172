.App {
  background: linear-gradient(to bottom, #1C104E 80%, #1affa7 100%);
  min-height: calc(100vh);
  margin: 0;
}

@font-face {
  font-family: 'Avenir Next Condensed';
  src: url('fonts/AvenirNextCondensed-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Condensed Heavy';
  src: url('fonts/AvenirNextCondensed-Heavy.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'Avenir Next Condensed Heavy Italic';
  src: url('fonts/AvenirNextCondensed-HeavyItalic.otf') format('opentype');
  font-weight: bold;
  font-style: bold;
}