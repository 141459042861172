/* CONTAINER */

.home-container {
  background-color: #1C104E;
  overflow-y: auto;
  padding-bottom: 1em;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh);
}

.home-container-mobile {
  background-color: #1C104E;
  overflow-y: auto;
  padding-bottom: 1em;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh);
}


/* LOGO */

.home-logo {
  width: 20em;
  height: 20em;
  margin: 3em;
}

.home-logo-mobile {
  width: 10em;
  height: 10em;
  margin: 3em;
}

.coin-logo {
  width: 10em;
  height: 10em;
  margin: 2em;
}

.coin-logo-mobile {
  width: 5em;
  height: 5em;
  margin: 1em;
}


.signout-text {
  color: #1affa7;
  font-size: 1em;
  font-weight: bold;
  margin: 0.5em;
  padding-top: 3em;
  cursor: pointer;
  font-family: 'Avenir Next Condensed Heavy';
}

.button {
  padding: 1em;  
  border: none;
  font-weight: bold;
  width: 10em;
  border-radius: 10px;
  background-color: #1affa7;
  color: #020202;
  margin: 1em;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

.button:hover { 
  background-color: #17a46e;
  color: #020202;
}

.button:active {
  /* Styles when the button is clicked */
  background-color: #17a46e; /* Darker shade for effect */
  color: #020202;
  transform: translateY(2px); /* Slightly move down */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for depth */
}

.input {
  padding: 12px;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 16px;
}

.input:focus {
  outline: none;
}

.signin-form {
  bottom: 0;      /* Align to the bottom of the viewport */
  width: 100%;    /* Optional: make it span the full width */
  justify-content: center; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buy-chips-button {
  width: 140px;
  padding: 12px;
  border: none;
  font-weight: bold;
  border-radius: 10px;
  background-color: #1affa7;
  color: #020202;
  margin-top: 10px;
  margin-bottom: 10px;
  margin: 0 10px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

.buy-chips-button:active {
  /* Styles when the button is clicked */
  background-color: #17a46e; /* Darker shade for effect */
  color: #020202;
  transform: translateY(2px); /* Slightly move down */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for depth */
}

.buy-chips-button:hover { 
  background-color: #17a46e;
  color: #020202;
}

.text-container {
  width: 80%;    /* Optional: make it span the full width */
  justify-content: center; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-dashboard {
  bottom: 0;      /* Align to the bottom of the viewport */
  width: 100%;    /* Optional: make it span the full width */
  justify-content: center; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.balance-text {
  font-size: 1.25em;
  color: #fff; 
  font-family: 'Avenir Next Condensed';
}


.buy-chips-row {
  display: flex;
  flex-direction: row;
  align-items: center; /* Centers buttons horizontally */
  justify-content: center; /* Optional: if you also want vertical centering */
  padding-top: 1em;
}

.buy-chips-row-mobile {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers buttons horizontally */
  justify-content: center; /* Optional: if you also want vertical centering */
  padding-top: 1em;
}

.buy-chips-button {
  width: 10em;
  margin-bottom: 10px; /* Spacing between buttons */
}
/* } */
