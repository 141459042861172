.rules { 
  padding: 5em;
  color: #ffffff;
}

a {
  color: #1affa7;
  /* text-decoration: none; */
}

h2 {
  color: #1affa7;
}

h3 {
  color: #1affa7;
}

h4 {
  color: #ffffff;
  font-style: italic;
}

li {
  color: #ffffff;
  font-size: 1em;
}