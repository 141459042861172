.card {
  color: #fff;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-mobile {
  color: #fff;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.directions {
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}
