/* CONTAINER */

.success-container {
    background-color: #020202;
    overflow-y: auto;
    padding-bottom: 1em;
    justify-content: center;
    align-items: center;
    /* margin-left: 20%;
    margin-right: 20%; */
  }
  
.success-container-mobile {
    background-color: #020202;
    overflow-y: auto;
    padding-bottom: 1em;
    justify-content: center;
    align-items: center;
}

/* LOGO */

.success-logo {
    width: 30em;
    height: 30em;
  }
  
  
.success-logo-mobile {
    width: 15em;
    height: 15em;
}

.event-card {
    color: #fff;  
    /* border: 1px solid #1affa7; */
    /* border-radius: 1em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
.event-card-mobile {
    color: #fff;  
    /* border: 1px solid #1affa7; */
    /* border-radius: 1em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-successful {
    color: #1affa7; 
}
  